import "./App.css";
import { useEffect, useRef, useState } from "react";
import { FaPlayCircle } from "react-icons/fa";
import CanvasSequence from "./CanvasSequence";

function Test() {
  const videoRef = useRef(null);
  const sweepRef = useRef(null);
  const specsRef = useRef(null);
  const scrollToRef = (ref) => ref.current.scrollIntoView();
  const playVideo = () => videoRef.current.play();

  const [isLoaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   console.log("assets loaded.");
  // }, []);

  return (
    <div className="track">
       <div className="fixed w-full z-50 top-0 flex-col md:flex-row justify-center items-center flex  md:items-center bg-black py-4">
        <a href="https://agorarobotics.com">
          <img
            className="md:hidden px-12 md:px-32 mb-4 h-10"
            src="agora_logo_white.png"
            alt="logo"
          />
        </a>
        <div className="flex flex-row md:space-x-2 font-rajdhani font-light text-white items-center md:mr-96">
          <a href="https://agorarobotics.com">
            <img
              className="hidden md:block h-10 mr-6"
              src="agora_logo_white.png"
              alt="logo"
            />
          </a>
          <a
            href="https://agorarobotics.com/solutions"
            className="w-24 md:w-24 text-center transition ease-in-out text-[21px] border-transparent hover:border-b hover:border-white hover:font-medium"
          >
            {" "}
            solutions{" "}
          </a>
          <a
            href="https://agorarobotics.com/about-us"
            className="w-24 md:w-24 text-center transition ease-in-out text-[21px] border-transparent hover:border-b hover:border-white hover:font-medium"
          >
            {" "}
            about us{" "}
          </a>
          <a
            href="https://agorarobotics.com/media"
            className="w-24 md:w-24 text-center transition ease-in-out text-[21px] border-transparent hover:border-b hover:border-white hover:font-medium"
          >
            {" "}
            media{" "}
          </a>

        </div>
        <div className="flex flex-row space-x-6 md:space-x-4 font-rajdhani font-semibold text-white items-center mt-2 md:mt-0">
          <a
            href="#sweep"
            onClick={() => scrollToRef(sweepRef)}
            className="transition ease-in-out text-center md:w-20 text-[21px] hover:font-bold hover:scale-110"
          >
            {" "}
            sweep{" "}
          </a>
          <a
            href="#specs"
            onClick={() => scrollToRef(specsRef)}
            className="transition ease-in-out text-center md:w-20 text-[21px] hover:font-bold hover:scale-110"
          >
            {" "}
            specs{" "}
          </a>
          <a
            href="https://agorarobotics.com/contact"
            onClick={() => scrollToRef(specsRef)}
            className="transition ease-in-out text-center md:w-32 text-[21px] hover:font-bold hover:scale-110"
          >
            {" "}
            contact us{" "}
          </a>
        </div>
      </div>
      <div className="sequence-wrap relative">
        <CanvasSequence loaded={isLoaded} setLoaded={setLoaded} />
      </div>
      <div className="mt-[490vh] md:mt-[820vh] z-40 absolute flex w-full justify-center flex-col">
      <div className="flex w-full justify-center items-center flex-col text-white space-y-10">
        <img alt="part_of" src="part_of.png" />
        <img alt="startup_awards" src="startup_awards.png" />
        <img alt="dia" src="dia.png" />
        <img alt="logo-bureau-veritas" src="logo-bureau-veritas.png" />
      </div>
        <div ref={sweepRef} className="flex justify-center pb-4 mt-64 md:mt-44 pt-10 md:pt-0">
          <video
            muted={true}
            poster="thumbnail.png"
            className="max-w-[350px] lg:max-w-[1000px]"
            ref={videoRef}
            controls
          >
            <source src="video.mp4" type="video/mp4" />
          </video>
        </div>

        <div ref={specsRef} className="flex justify-center pb-16 md:pb-32 font-rajdhani text-white space-x-4">
          <span className="font-semibold text-2xl md:text-2xl">watch the film </span>
          <button onClick={playVideo}>
            {" "}
            <FaPlayCircle className="transition my-auto h-8 w-8 text-white hover:text-gray-400" />
          </button>
        </div>
        <div className="flex justify-center pb-10 font-rajdhani">
          <a href="https://agorarobotics.com/contact" className="transition ease-in w-32 text-black bg-white hover:bg-gray-300 hover:font-semibold text-center text-lg py">
            contact us
          </a>
        </div>
        <div className="flex w-full justify-center md:mt-16 mt-32">
          <div className="flex w-full w-[1000px] md:max-w-[900px] px-6 md:px-16 text-white font-oxygen font-light flex-col">
            <div className="flex flex-row justify-between">
              <span className="md:text-3xl md:px-8 font-bold mb-4">
                specifications
              </span>
            </div>
            <span className="flex bg-gray-300 h-0.5 w-full" />
            <div className="flex flex-col md:px-8 w-full mt-3 space-y-10 mb-32">
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="md:w-1/2">size</span>
                <span className="w-1/2 md:ml-64">735 x 530 x 710 mm</span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="md:w-1/2">weight</span>
                <span className="w-1/2 md:ml-64">115kg</span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="md:w-1/2">solid metal build</span>
                <span className="md:w-1/2 md:ml-64 hidden">115kg</span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="flex flex-col md:w-1/2">
                  stainless steel tanks:
                  {<div> clean water </div>} {<div> used water </div>}
                </span>
                <span className="flex flex-col w-1/2 md:ml-64">
                  {<div className="invisible"> filler </div>}
                  {<div> 24 liters </div>} {<div> 26 liters </div>}
                </span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="flex flex-col md:w-1/2">
                  {<div> brush width </div>} {<div> suction blade width </div>}
                </span>
                <span className="flex flex-col w-1/2 md:ml-64">
                  {<div> 450mm </div>} {<div> 450mm </div>}
                </span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="flex flex-col md:w-1/2">
                  {<div> productivity </div>}
                </span>
                <span className="flex flex-col w-1/2 md:ml-64">
                  {<div> 12.500 sqm/day </div>}
                </span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm">
                <span className="flex flex-col md:w-1/2">
                  {<div> cleaning cycle </div>} {<div> charging cycle </div>}
                </span>
                <span className="flex flex-col w-1/2 md:ml-64">
                  {<div> 8 hours </div>} {<div> 2 hours </div>}
                </span>
              </div>
              <div className="flex flex-row justify-between md:text-2xl text-sm" >
                <span className="md:w-1/2">warranty by replacement</span>
                <span className="w-1/2 md:ml-64">3 years</span>
              </div>
            </div>
            <span className="flex w-full mt-16 mb-24 text-center text-2xl font-medium">
              support, service and warranty provided directly by the manufacturer
            </span>
            <div className="flex w-full justify-center font-rajdhani">
              <a href="https://agorarobotics.com/contact" className="transition ease-in w-32 text-black bg-white hover:bg-gray-300 hover:font-semibold text-center text-lg py">
                contact us
              </a>
            </div>
            <span className="mb-96" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test;
